import * as React from 'react';

import Modal from 'react-bootstrap/Modal';

interface IEcommPopupProps {
    showPopup: boolean;
    callback: any;
    url: string;
    title: string;
    body: string;
    linkText: string;
}

/**
 *
 * Event component
 * @extends {React.PureComponent}
 */
export class Popup extends React.PureComponent<IEcommPopupProps> {
    public render(): JSX.Element | null {
        return (
            <Modal show={this.props.showPopup} onHide={this._callbackHide} centered>
                <Modal.Header closeButton={true} style={{ border: 0 }}>
                    <Modal.Title className='ecomm-portal-popup-title'>{this.props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='ecomm-portal-popup-body'>
                        <p>{this.props.body}</p>
                    </div>
                </Modal.Body>

                <Modal.Footer style={{ border: 0 }}>
                    <div className='ecomm-portal-popup-wrapper'>
                        <a target='_blank' href={this.props.url} onClick={this._callbackRedirectCheckout} className='ecomm-portal-popup-button ecomm-portal-popup-button-stay' style={{ width: '100%' }}>{this.props.linkText}</a>
                    </div>
                </Modal.Footer>

            </Modal>
        );
    }

    private _callbackRedirectCheckout = () => {
        this.props.callback();
    }

    private _callbackHide = () => {
        event?.preventDefault();
        this.props.callback();
    }
}

export default Popup;