import * as React from 'react';

import Popup from './../../../components/ecomm-portal-popup';
import { ICoreContext } from '@msdyn365-commerce/core';
import { ICartState } from '@msdyn365-commerce/global-state';
import { observer } from 'mobx-react';

export interface IQuickLink {
  name: string;
  categoryId: number;
  url: string;
  target: string;
  filterName: string;
  isPortal: boolean;
  mobileMode: boolean;
  locale: string;
  context: ICoreContext;
  cart?: ICartState;
  enableNewLable?: boolean;
  newLableText?: string;
}

/**
 *
 * QuickLink component
 * @extends {React.PureComponent<IQuickLink>}
 */

@observer
export class QuickLink extends React.PureComponent<IQuickLink> {
  public state = { width: 0, showPopup: false, cartHasItems: false };

  public render(): JSX.Element | null {
    const linkStyle = this.props.url === '' ? 'no-link' : (this.props.isPortal ? 'portal' : '');
  
    return (
      <div className='quicklink-item-group'>
       {!this.props.isPortal && <a href={!this.props.isPortal ? this.props.url : ''} onClick={!this.props.isPortal ? this._handleOnClick : this._handleOnClickPopup} target={!this.props.isPortal ? this.props.target : ''} className={linkStyle}>{this.props.name}</a>}
        {this.props.isPortal && <a href= {this.props.url} onClick={this._handleOnClickPopup} target={!this.props.isPortal ? this.props.target : ''} className={linkStyle}>{this.props.name}</a>}
        {this.props.isPortal && <Popup showPopup={this.state.showPopup} name={this.props.name} url={this.props.url} isMobile={this.props.mobileMode} isPortalPopup={true} locale={this.props.locale} callback={this._handleCallback} context={this.props.context} />}
        {this.props.enableNewLable && <label className='quicklink-item-new'>{this.props.newLableText}</label>}
      </div>
    );

  }

  private _handleOnClick = () => {
    if (window !== undefined) {
      window.sessionStorage.setItem('qLink', this.props.categoryId.toString());
      window.sessionStorage.setItem('qItem', this.props.name);
      window.sessionStorage.setItem('qFilter', this.props.filterName);
    }
  }

  private  _handleOnClickPopup = async() => {
   
    const currerntCart = this.props.cart;

    if(currerntCart && currerntCart.totalItemsInCart > 0)
    {
      event?.preventDefault();
      this.setState({ showPopup: true});
    }
    else{
      this.setState({ showPopup: false});
    }

  }

  private _handleCallback = () => {
    this.setState({ showPopup: false});
  }
}

export default QuickLink;