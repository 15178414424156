// <= 999 = Mobile
// >= 1000 = Tablet and Desktop

export const enum Breakpoints {
  small = 540,
  medium = 768,
  large = 992
}

export function isMobile (width: number): boolean {
  return width < Breakpoints.large;
}