import * as React from 'react';

import Modal from 'react-bootstrap/Modal';
import { getUrlSync } from '@msdyn365-commerce/core';
import { ICoreContext } from '@msdyn365-commerce/core';

interface IEcommPopupProps
{
    showPopup: boolean;
    callback: any;
    name: string;
    isMobile: boolean;
    isPortalPopup: boolean;
    locale: string;
    url: string;
    context: ICoreContext;
}

/**
 *
 * Event component
 * @extends {React.PureComponent}
 */
export class Popup extends React.PureComponent<IEcommPopupProps> {
  public render(): JSX.Element | null {
    return (
        <Modal show={this.props.showPopup} onHide={this._callbackHide} centered>
            <Modal.Header closeButton={this.props.isPortalPopup ? true : false} style={{border: 0}}>
                <Modal.Title className='ecomm-portal-popup-title'>{this._titleText()}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {this._renderMessage()}
            </Modal.Body>

            <Modal.Footer style={{border: 0}}>
                
                <div className='ecomm-portal-popup-wrapper'>
                <a href={this._onClickCheckout()} onClick={this._callbackRedirectCheckout} className='ecomm-portal-popup-button ecomm-portal-popup-button-stay' style={this.props.isMobile ? { width: 100 + '%'} : { width: 100 + '%'}}>{this._stayButtonText()}</a>
                </div>

                <div className='ecomm-portal-popup-wrapper'>   
                <a href={this.props.url} target={this.props.isPortalPopup? '_blank' : '_self'} onClick={this._callbackRedirect} className={this.props.isPortalPopup ? 'ecomm-portal-popup-button ecomm-portal-popup-button-go-portal' : 'ecomm-portal-popup-button ecomm-portal-popup-button-go'} style={this.props.isMobile ? { width: 100 + '%'} : { width: 100 + '%'}}>{this._goButtonText()}</a>
                </div>        
                
            </Modal.Footer>
            
        </Modal>
    );
  }

  private _callbackRedirect = () => {
    this.props.callback();
    setTimeout(()=>{
      window.location.reload();
    },1000)
  }

  private _callbackRedirectCheckout = () => {
    this.props.callback();
  }

  private _callbackHide = () => {
    event?.preventDefault();
    this.props.callback();
  }

  private _onClickCheckout = () =>{
    const checkoutLink = getUrlSync('checkout', this.props.context.actionContext) || '';
    const url = window.location.origin + checkoutLink;
    return url;
  }

  private _titleText(): string {
    if (!this.props.isPortalPopup) {
      return this.props.name;
    }

    if (this.props.locale === 'zh') {
      return '别急着走！';
    } else {
      return "Don't Leave Just Yet!";
    }
  }

  private _stayButtonText(): string {
    if (this.props.locale === 'zh') {
      if (this.props.isPortalPopup) {
        return '去结账';
      } else {
        return '稍后登入';
      }
    } else {
      if (this.props.isPortalPopup) {
        return 'Checkout';
      } else {
        return 'Maybe Later';
      }
    }
  }

  private _goButtonText(): string {
    if (this.props.locale === 'zh') {
      if (this.props.isPortalPopup) {
        return '离开';
      } else {
        return '登入户口';
      }
    } else {
      if (this.props.isPortalPopup) {
        return 'Leave';
      } else {
        return 'Go to Sign In Page';
      }
    }
  }

  private _renderMessage(): JSX.Element | null {
    
    if (this.props.locale === 'zh') {
      if (this.props.isPortalPopup) {
        return (
          <div className='ecomm-portal-popup-body'>
              <p>您的购物车中有物品。</p>
              <p>离开之前立即完成购买！</p>
          </div>
        );
      } else {
        return (
          <div className='ecomm-portal-popup-body'>
          </div>
        );
      }
    } else {
      if (this.props.isPortalPopup) {
        return (
          <div className='ecomm-portal-popup-body'>
              <p>There are items in your cart.</p>
              <p>Complete your purchase now before leaving!</p>
          </div>
        );
      } else {
        return (
          <div className='ecomm-portal-popup-body'>
          </div>
        );
      }
    }
  }
}

export default Popup;